<template>
    <div class="body">
        <div class="details_top">
            <div>{{renderStatus.title}}</div>
            <div>{{renderStatus.tip}}</div>
        </div>
        <!--<div class="details_top">-->
            <!--<div style="padding-top: 10vw">很抱歉，今天预约已满</div>-->
        <!--</div>-->
        <div class="details_content">
            <div class="details_content1">
                <div class="details_content1_left">
                    <div>{{business.business_name}}</div>
<!--                    <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011801.png">-->
                </div>
                <div class="details_content1_right" v-if="business.phone">
                    <a :href="'tel:' + business.phone">
                        <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011803.png">
                    </a>
                    <!--<img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011802.png">-->
                </div>
            </div>
            <div class="details_content2">
                <div class="details_content2_word1">
                    <div class="conten-item">
                        <div class="">姓名</div>
                        <div>{{reserve.name}}</div>
                    </div>
                    <div class="conten-item">
                        <div>性别</div>
                        <div>{{reserve.sex === 1 ? '男' : '女'}}</div>
                    </div>
                </div>
                <div class="details_content2_word1">
                    <div class="conten-item">
                        <div>电话</div>
                        <div>{{reserve.phone}}</div>
                    </div>
                </div>
                <div class="details_content2_word1">
                    <div class="conten-item">
                        <div>日期</div>
                        <div>{{reserve.date}}</div>
                    </div>
                    <div class="conten-item">
                        <div>时间</div>
                        <div>{{reserve.time}}</div>
                    </div>
                </div>
                <div class="details_content2_word1">
                    <div class="conten-item">
                        <div>包厢</div>
                        <div>{{reserve.room}}</div>
                    </div>
                    <div class="conten-item">
                        <div>忙时调位</div>
                        <div>{{reserve.canChange ? '是' : '否'}}</div>
                    </div>
                </div>
                <div class="details_content2_word1">
                        <div class="" style="min-width: 3rem;">备注</div>
                        <div>{{reserve.remark}}</div>
                </div>
                <div class="details_content2_word1">
                        <div class="">下单时间</div>
                        <div>{{reserve.create_time}}</div>
                </div>
                <!--<div  class="details_content2_word2">-->
                    <!--<div>添加到日历</div>-->
                    <!--<div>取消预定</div>-->
                <!--</div>-->
            </div>
        </div>
        <div class="details_ewm" v-if="reserve.status == 1">
            <div class="details_ewm_title">预约凭证</div>
            <div class="details_ewm_ewm">
                <vue-qr id="qr" :text="checkUrl"></vue-qr>
            </div>
<!--            <div class="details_ewm_ewm"></div>-->
<!--            <div class="details_ewm_tip">预约已成功，祝您用餐愉快</div>-->
        </div>
        <!--<div class="details_content2_word3">-->
            <!--<div>添加到日历</div>-->
            <!--<div>取消预定</div>-->
            <!--<div>重新预定</div>-->
        <!--</div>-->
        <div class="subscribe_product" v-if="order.gooods_name" @click="viewOrder">
            <div class="subscribe_product_box">
                <img class="subscribe_product_img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + business.trademark"/>
                <div class="subscribe_product_content">
                    <div class="subscribe_product_content_title">{{order.gooods_name}}</div>
                    <div class="subscribe_product_content_money">
                        <div>￥</div>
                        <div>{{order.total_fee/100}}</div>
                    </div>
                    <div class="subscribe_product_content_time">{{order.create_time}}</div>
                </div>
            </div>
        </div>
        <!--<img class="details_button" src="https://seetop-1257860468.file.myqcloud.com/html/img/button2023011801.png">-->
    </div>
</template>

<script>
    import { globalConfig } from '../../utils/mixin'
    import { hrefUrlCommon } from '../../utils/tools'
    import VueQr from 'vue-qr'
    export default {
        name: 'Comment',
        mixins: [globalConfig],
        data () {
            return {
                id: 0,
                business: 5,
                order: [],
                reserve: [],
                checkUrl: '666'
            }
        },
        components: { VueQr },
        methods: {
            hrefUrlCommon,
            init: function () {
                this.axios.post(this.apiUrl + 'online/reserve/reserveOrderInfo', {
                    reserveId: this.$route.params.id
                }).then((response) => {
                    console.log(response)
                    this.business = response.data.info.business
                    this.order = response.data.info.order
                    this.reserve = response.data.info.reserve
                    this.checkUrl = response.data.info.qr
                })
            },
          viewOrder: function () {
            this.$router.push('/order/' + this.order.id)
          }
        },
        created () {
            this.init()
        },
      computed: {
          renderStatus: function () {
            let resText = {
              title: '',
              tip: ''
            }
            console.log('this.reserve.status', this.reserve.status)
            switch (this.reserve.status) {
              case '0':
                resText = {
                  title: '预约中',
                  tip: '等待商家处理'
                }
                break
              case '1':
                resText = {
                  title: '预定成功',
                  tip: '请按时到店，祝您用餐愉快'
                }
                break
              case '2':
                resText = {
                  title: '已使用',
                  tip: '欢迎再次光临'
                }
                break
              case '-1':
                resText = {
                  title: '已过期',
                  tip: ''
                }
                break
              case '-2':
                resText = {
                  title: '已取消',
                  tip: ''
                }
                break
              case '-3':
                resText = {
                  title: '被拒绝',
                  tip: this.reserve.fail_reason
                }
                break
              default:
                break
            }
            return resText
          }
      }
    }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .details_top {
        width: 100vw;
        height: 32.5vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/img2023011801.png") #F8F8F8;
        background-size: 100vw 32.5vw;
    }
    .details_top div:nth-of-type(1){
        font-size: 6.4vw;
        font-weight: 900;
        padding: 5vw 7vw 2vw 7vw;
        color: #fff;
    }
    .details_top div:nth-of-type(2){
        font-size: 4.2vw;
        font-weight: 500;
        padding: 0 7vw;
        color: #fff;
    }
    .details_content{
        width: 90vw;
        margin: 1vw 5vw;
        background: #ffffff;
        border-radius: 2vw;
    }
    .details_content1{
        display: flex;
        justify-content: space-between;
        background: #cecece;
        padding: 0.6rem 0 0.6rem 0.8rem;
        border-radius: 2vw 2vw 0 0;
    }
    .details_content1_left{
        display: flex;
        align-items: center;
        font-size: 3.8vw;
        font-weight: 600;
    }
    .details_content1_left img{
        width: 1.5vw;
        margin: 0 2vw;
    }
    .details_content1_right{
        display: flex;
        align-items: center;
    }
    .details_content1_right img:nth-of-type(1){
        width: 3.8vw;
        margin: 0 2vw;
    }
    .details_content1_right img:nth-of-type(2){
        width: 3vw;
        margin: 0 1vw 0 0;
    }
    .details_content2{
        padding: 0 2rem 1.5rem 2rem;
        box-sizing: border-box;

    }
    .details_content2_word1{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        font-size: 0.9rem;
        /*color: #000000;*/
        /*margin: 0.5rem 0 0 0;*/
        padding: 0.5rem 0;
        border-bottom: 1px solid #e2e2e2;
    }
    .conten-item {
        width: 50%;
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    /*.item-label {*/
    /*    min-width: 5rem;*/
    /*}*/
    .conten-item div:nth-of-type(1){
        margin-right: 4vw;
    }
    .conten-item div:nth-of-type(2){
    }
    .details_content2_word2{
        display: flex;
        width: 60vw;
        font-size: 3.2vw;
        font-weight: 600;
        margin-left: 35vw;
        margin-top: 6vw;
    }
    .details_content2_word2 div{
        padding: 0.8vw 3vw;
        border: 2px solid #A5A5A5;
        border-radius: 5vw;
        margin: 0 1.5vw;
    }
    .details_ewm{
        width: 90vw;
        margin: 3vw 5vw;
        background: #fff;
        padding-bottom: 5vw;
        border-radius: 2vw;
    }
    .details_ewm_title{
        padding: 3vw 4vw;
        font-size: 4vw;
        font-weight: 600;
    }
    .details_ewm_ewm{
        width: 50vw;
        margin: 0 auto;
        display: block;
    }
    .details_ewm_tip{
        font-weight: 500;
        font-size: 3.2vw;
        width: 100%;
        text-align: center;
    }
    .details_content2_word3{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10vw 0;
        padding: 0 5vw;
    }
    .details_content2_word3 div{
        background: #fff;
        border: 1px solid #A5A5A5;
        border-radius: 5vw;
        padding: 0.8vw 3vw;
        font-size: 3.2vw;
    }
    .details_button{
        width: 86vw;
        margin: 0 7vw;
        text-align: center;
    }
    .subscribe_product{
        width: 100vw;
        margin: 3vw 0;
        padding: 5vw 4vw;
        background: #fff;
    }
    .subscribe_product_title{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #CDCDCD;
        font-size: 3.8vw;
    }
    .subscribe_product_title div:nth-of-type(1),.subscribe_product_title div:nth-of-type(3){
        width: 28vw;
        border-bottom: 1px solid #CDCDCD;
        margin: 0 1vw;
    }
    .subscribe_product_box{
        display: flex;
        align-items: center;
    }
    .subscribe_product_choose{
        width: 4vw;
        height: 4vw;
        border-radius: 4vw;
        border: 1px solid #ccc;
        margin: 0 4vw 0 2vw;
    }
    .subscribe_product_choose_yes{
        width: 4vw;
        height: 4vw;
        border-radius: 4vw;
        margin: 0 4vw 0 2vw;
        border: 1px solid #DC9A64;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2023011806.png") center center no-repeat #DC9A64;;
        background-size: 1.5vw;
    }
    .subscribe_product_img{
        width: 20vw;
        height: 20vw;
        border-radius: 2vw;
        border:1px solid #ececec;
        margin:0 4vw 0 0;
    }
    .subscribe_product_content_title{
        font-size: 4vw;
        font-weight: 600;
    }
    .subscribe_product_content_tip{
        font-size: 3.2vw;
        color: #B4B4B4;
        margin: 1.2vw 0;
    }
    .subscribe_product_content_time{
        font-size: 3.8vw;
        color: #B4B4B4;
        margin-top: 1.5vw;
    }
    .subscribe_product_content_money{
        display: flex;
        align-items: flex-end;
        height: 6vw;
        margin-top: 1.5vw;
    }
    .subscribe_product_content_money div:nth-of-type(1){
        font-size: 2.8vw;
        color: #F88A66;
        margin-top: 0.8vw;
        font-weight: 800;
    }
    .subscribe_product_content_money div:nth-of-type(2){
        font-size: 3.8vw;
        font-weight: 800;
        color: #F88A66;
    }
    .subscribe_product_content_money div:nth-of-type(3){
        font-size: 2.8vw;
        padding: 0.5vw 0.8vw;
        color: #FED8B8;
        border: 1px solid #FED8B8;
        margin: 0 1.5vw;
    }
    .subscribe_product_content_money del{
        font-size: 3.6vw;
        color: #CDCDCD;
    }
</style>
